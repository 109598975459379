
const Footer = () => {
  return (
    < div className='footer'>
        <div className="top">
            <div>
                <h1>HM Travels</h1>
                <p>Choose your favorite destination</p>
            </div>
            <div>
                <a href='/'>
                <i className='fa-brands fa-facebook-square'></i>
                </a>
                <a href='/'>
                <i className='fa-brands fa-instagram-square'></i>
                </a>
                <a href='/'>
                <i className='fa-brands fa-behance-square'></i>
                </a>
                <a href='/'>
                <i className='fa-brands fa-twitter-square'></i>
                </a>
            </div>
        </div>
        <div className="bottom">
            <div>
                <h4>Projects</h4>
                <a href='/'>Changelog</a>
                <a href='/'>Status</a>
                <a href='/'>Licence</a>
                <a href='/'>All vertions</a>

            </div>
            <div>
                <h4>Community</h4>
                <a href='/'>GitHub</a>
                <a href='/'>Issues</a>
                <a href='/'>Projects</a>
                <a href='/'>Twitter</a>

            </div>
            <div>
                <h4>Help</h4>
                <a href='/'>Support</a>
                <a href='/'>Troubleshooting</a>
                <a href='/'>Contact us</a>

            </div>
            <div>
                <h4>Others</h4>
                <a href='/'>Terms of Services</a>
                <a href='/'>Privacy policy</a>
                <a href='/'>Licence</a>

            </div>
        </div>
      
    </div>
  )
}

export default Footer
