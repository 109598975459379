import { useState } from "react";
import { NavLink } from "react-router-dom";

export const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <header>
        <div className="navbar-container">
          <div className="logo-brand">
            <a href="/">HM Tour & Travels</a>
          </div>
          <div className={`menu-toggle ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
            &#9776; {/* Hamburger Icon */}
          </div>
          <ul className={isMenuOpen ? 'open' : ''}>
            <li> <NavLink to="/">HOME</NavLink> </li>
            <li> <NavLink to="/about">ABOUT</NavLink> </li>
            <li> <NavLink to="/service">SERVICE</NavLink></li>
            <li> <NavLink to="/contact">CONTACT</NavLink></li>         
                <li>
                  <NavLink to="/register"> REGISTER </NavLink>
                </li>
               
          </ul>
        </div>
      </header>
    </>
  );
};
