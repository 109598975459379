

import { NavLink } from "react-router-dom";

export const About = () => {
  return (
    <>
      <main className="about-main">
        <section className="about">
          <h2 className="welcome-message">
            WELCOME TO OUR WEBSITE
          </h2>

          <div className="about-container">
            <div className="about-content">
              <h1 className="about-heading">Why Choose Us? </h1>
              <p className="about-para">
                Founded with a passion for exploration and a deep love for discovering new cultures, [HM TOUR & TRAVELS] began as a small travel service with a simple mission: to make travel more accessible and unforgettable for everyone. Our founders, inspired by their own adventures around the world, saw the potential to create bespoke travel experiences that not only catered to wanderlust but also offered a deeper connection to the places and people travelers visit.

                What started as a dream quickly grew into a trusted travel service known for its attention to detail, personalized itineraries, and a commitment to quality. Over the years, we have expanded our offerings to include guided tours, customized travel plans, and group packages that cater to all types of travelers—from solo adventurers to families and corporate groups.

                At [HM TOUR & TRAVELS], we believe travel is more than just visiting a destination; its about creating memories, learning from new experiences, and connecting with people across the globe. Our journey has been one of growth and passion, and today, we continue to offer our clients unforgettable experiences that go beyond the ordinary.

              </p>

              <div className="button-group">
                <NavLink to="/contact">
                  <button className="about-btn">Connect Now</button>
                </NavLink>
                <NavLink to="/service">
                  <button className="about-btn">Learn More</button>
                </NavLink>
              </div>
            </div>

            <div className="about-image2">
              <img
                src="/images/travelabout.gif"
                alt="Travel Experience"
                width="600"
                height="700"
              />
            </div>
          </div>

          {/* Move "Our Mission" below the image */}
          <section className="our-mission">
            <h2 className="about-heading">Our Mission</h2>
            <p className="about-para">
              A tour operator is a travel company that plans, organizes, and sells
              trips and holiday packages. Tour operators can create package tours that include transportation,
              accommodation, meals, sightseeing, and activities.
            </p>
            <p className="about-para">
              Affordability: We offer competitive pricing without compromising
              on the quality of our services.
            </p>
            <p className="about-para">
              Reliability: Count on us to be there when you need us. We are committed to ensuring your travel is reliable and available 24/7.
            </p>
          </section>
        </section>
      </main>
    </>
  );
};
