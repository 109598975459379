import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import App from './App.js'
import "./index.css"
import {ToastContainer} from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

createRoot(document.getElementById('root')).render(
     <StrictMode>
    <App />
    <ToastContainer
    position='top-right'
    autoClose={3000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme='colored'
/>
  </StrictMode>
 
)

