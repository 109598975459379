import { NavLink } from "react-router-dom";
import image1 from "../Images/Tirqthgarh Waterfall.jpg"
import image2 from "../Images/Wild life in Bandipur.jpg"
// import image3 from "../Images/athirapally waterfalls🌊.jpg"
// import image4 from "../Images/Troodos Mountains.jpg"


export const Home = () => {
  return (
    <>
      <div className="hero">
        <img src="/images/background1.jpg" alt="heroimg" className='hero-img' />
        <div className="hero-text">
          <h1>Memories made forever</h1>

          <p>choose your favorite destination</p>

        </div>
      </div>

      <div className='destination'>

        <h1>Popular Destination</h1>
        <p>tour gives you a opportunity</p>
        <div className="first-des">
          <div className="des-text">
            <h2>Chitrakoot Waterfall</h2>
            <p>Known as the gateway to Bastar, Jagdalpur is famous for its tribal culture and beautiful waterfalls. The Chitrakoot Waterfall, often referred to as the Niagara of India, is a major tourist attraction, along with the Tirathgarh Waterfall.
              A peaceful town surrounded by dense forests, Jagdalpur is the perfect spot for nature lovers and those interested in exploring the indigenous tribes and their vibrant cultures</p>
          </div>
          <div className="image"><img src={image1} width="500"
            height="400" alt="not found" />
            {/* <img src={image3} alt="not found" width="500"
              height="400" /> */}
          </div>

        </div>

        <div className="first-des-reverse">
          <div className="des-text">
            <h2>Barnawapara Wildlife Sanctuary</h2>
            <p>This sanctuary is home to diverse wildlife, including tigers, leopards, wild boars, and numerous bird species. Its known for its rich flora and fauna and offers opportunities for wildlife safaris and nature walks.
              Situated in the Mahasamund district, this sanctuary is perfect for nature lovers and wildlife enthusiasts.</p>
          </div>
          <div className="image"><img src={image2} width="500"
            height="400" alt="not found" />
            {/* <img src={image4} alt="not found" width="500"
              height="400" /> */}
          </div>

        </div>
      </div>


      <main>
        <section className="section-hero">

          <div className="hero-content">
            <p>Search your destination with us.</p>
            <h1>Welcome to HM Tours & Travels</h1>
          
            <p>
              Count on us to be there when you need us.
              committed to ensuring your TRAVEL is reliable and
              available 24/7.
            </p>
            <h2>SHOP NO.14, Subhash Stadium Complex Raipur(C.G.)</h2>
            <h1 className="service-heading1">SERVICES</h1>
            <img src="images/service1car.png" alt="car rent" className="card-image1" />

            <div className="service-cards-container">

              <div className="service-card1">

                <h3 className="card-title1">CAR RENT</h3>
                <p className="card-description1"> 9425214360</p>
              </div>

              <div className="service-card1">
                <h3 className="card-title1">TOUR OPERATOR</h3>
                <p className="card-description1"> 9425214360</p>
              </div>
              <div className="service-card1">
                <h3 className="card-title1">TAXI SERVICE</h3>
                <p className="card-description1"> 9425214360</p>
              </div>


            </div>
            <NavLink to="/contact">
              <button className="btnhome">connect now</button>
            </NavLink>
            <NavLink to="/service">
              <button className="btnhome">learn more</button>
            </NavLink>
          </div>

          {/* hero images  */}
        </section>
      </main>


    </>
  );
};
