
import {BrowserRouter, Route, Routes} from "react-router-dom"
import { Home } from "./pages/Home"
import { About } from "./pages/About"
import { Contact } from "./pages/Contact"
import { Register } from "./pages/Register"
import { Service } from "./pages/Service"
 import { Error } from "./pages/Error"
 import Footer from "./Components/Footer"
import { Navbar } from "./Components/Navbar"
const App=()=>{
  return <>
  <BrowserRouter>
  <Navbar/>
  <Routes>
    <Route path="/" element={<Home/>}/>
    <Route path="/about" element={<About/>}/>
    <Route path="/contact" element={<Contact/>}/>
     <Route path="/service" element={<Service/>}/>
    <Route path="/register" element={<Register/>}/>
    <Route path="*" element={<Error/>}/> 



  </Routes>
  <Footer/>
  </BrowserRouter>
  </>
}

export default App